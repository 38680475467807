<template>
  <div class="font-source">
    <!-- Icons and lines -->
    <div class="flex justify-center gap-4 items-center">
      <div class="p-4 rounded-full bg-ansLemon">
        <img
          src="@/assets/images/icons/user.svg"
          alt="icons"
          class="w-7 h-7 lg:w-12 lg:h-12"
        />
      </div>

      <div
        :class="`w-3/12 ${
          $store.state.general.step == 5 ? 'bg-turqGreen' : 'bg-gray-50'
        }`"
        style="max-width: 200px; height: 5px"
      ></div>

      <div
        :class="`p-4 rounded-full ${
          $store.state.general.step == 5 ? 'bg-ansLemon' : 'bg-gray-50'
        }`"
      >
        <img
          src="@/assets/images/icons/receipt.svg"
          alt="icons"
          :class="`w-7 h-7 lg:w-12 lg:h-12 ${
            $store.state.general.step == 5 ? 'greener' : ''
          }`"
        />
      </div>
    </div>

    <PersonalInfo v-if="$store.state.general.step <= 4" @done="userDone" />

    <AddRevenue v-if="$store.state.general.step == 5" @done="generateInvoice" />
  </div>
</template>

<script>
import AddRevenue from "@/components/home/AddRevenue";
import PersonalInfo from "@/components/home/PersonalInfo";
export default {
  name: "GenerateInvoice",

  components: {
    PersonalInfo,
    AddRevenue,
  },

  data() {
    return {
      user: null,
      invoice: null,
    };
  },

  methods: {
    generateInvoice() {
      this.$emit("doned");
    },
    userDone(user) {
      this.user = user;
    },
    next() {
      const step = this.$store.state.general.step + 1;
      this.$store.commit("general/updateStep", step);
      this.$router.push({
        name: "Home",
        query: { st: step },
      });
    },
  },
};
</script>

<style scoped></style>
