<template>
  <div class="flex justify-content-between gap-3">
    <HistoryLine :done="true" class="" />

    <div class="border border-gray-300 rounded p-6 w-full">
      <h4 class="font-bold lg:text-2xl">
        Invoice {{ invoiceData.invoiceNumber }}
      </h4>

      <div class="flex gap-2 items-center">
        <div
          class="w-10 h-10 rounded-full bg-ansLemon flex items-center justify-center"
        >
          <img src="@/assets/images/icons/withdrawal.svg" alt="icons" />
        </div>

        <p class="text-xs lg:text-base">
          {{ invoiceData.invoiceAmount | toCurrency }}
        </p>
      </div>
      <div class="flex gap-2 items-center mt-3">
        <div
          class="w-10 h-10 rounded-full bg-ansLemon flex items-center justify-center"
        >
          <img src="@/assets/images/icons/calendar.svg" alt="icons" />
        </div>

        <p class="text-xs lg:text-base">
          Generated on {{ invoiceData.dateOfIssuance | moment("DD/MM/YYYY") }}
        </p>
      </div>

      <div class="flex flex-col lg:flex-row gap-3 lg:justify-between">
        <button
          @click="$emit('showview', invoiceData.invoiceNumber)"
          class="mt-3 text-xs py-3 lg:py-4 px-[70px] lg:px-[117px] text-white bg-ansGreen rounded"
        >
          View invoice
        </button>

        <div class="flex items-center gap-3 w-full lg:w-auto">
          <button
            :disabled="sendingInvoice"
            @click="sendInvoice"
            class="flex justify-center items-center text-xs lg:text-base gap-2 py-2 px-3 lg:px-5 rounded border border-gray-300 w-1/2 lg:w-auto"
          >
            <img
              src="@/assets/images/email/email_send.svg"
              alt="icons"
              class="inline"
            />
            <span>Send via email</span>
            <spinner v-if="sendingInvoice" class="" color="ansGreen" />
          </button>

          <button
            :disabled="getting"
            @click="printInvoice"
            class="flex justify-center items-center text-xs lg:text-base gap-2 py-2 px-3 lg:px-5 rounded border border-gray-300 w-1/2 lg:w-auto"
          >
            <img
              src="@/assets/images/email/print.svg"
              alt="icons"
              class="inline w-5 h-5"
            />
            <span>Print</span>
            <spinner v-if="getting" class="" color="ansGreen" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HistoryLine from "@/components/track/HistoryLine.vue";
export default {
  name: "InvoiceCard",

  components: {
    HistoryLine,
  },

  props: {
    invoiceData: {
      type: Object,
      default: () => ({
        invoiceNumber: "15612483070",
        invoiceStatus: "unpaid",
        invoiceAmount: 400000,
        dateOfIssuance: "2023-05-23 10:54:39",
      }),
    },
  },

  data() {
    return {
      getting: false,
      sendingInvoice: false,
    };
  },

  methods: {
    async sendInvoice() {
      try {
        this.sendingInvoice = true;

        const res = await this.$http.post(
          "invoice/send-invoice",
          {
            invoiceNumber: this.invoiceData.invoiceNumber,
            baseUrl: `${window.location.origin}/invoice`,
          },
          {
            headers: { noauth: true },
          }
        );
        this.sendingInvoice = false;

        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (err) {
        console.log(err);
        this.sendingInvoice = false;
      }
    },

    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.post(
          "/invoice/" + this.invoiceData.invoiceNumber,
          {
            baseUrl: `${window.location.origin}/invoice`,
          },
          {
            headers: { noauth: true },
          }
        );
        this.getting = false;

        if (!res) {
          return;
        }

        const { data } = res;
        return data;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },

    async printInvoice() {
      const divContents = await this.getData();

      const a = window.open("", "", "");
      a.document.write(
        "<html><head><title>Invoice" +
          this.invoiceData.invoiceNumber +
          "</title></head>"
      );
      a.document.write("<body>");
      a.document.write(divContents);
      a.document.write("</body></html>");
      a.document.close();
      a.focus();
      setTimeout(() => {
        a.print();
        a.close();
        return true;
      }, 700);
    },
  },
};
</script>

<style scoped></style>
