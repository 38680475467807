var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-source"},[_c('div',{staticClass:"flex justify-center gap-4 items-center"},[_vm._m(0),_c('div',{class:`w-3/12 ${
        _vm.$store.state.general.step == 5 ? 'bg-turqGreen' : 'bg-gray-50'
      }`,staticStyle:{"max-width":"200px","height":"5px"}}),_c('div',{class:`p-4 rounded-full ${
        _vm.$store.state.general.step == 5 ? 'bg-ansLemon' : 'bg-gray-50'
      }`},[_c('img',{class:`w-7 h-7 lg:w-12 lg:h-12 ${
          _vm.$store.state.general.step == 5 ? 'greener' : ''
        }`,attrs:{"src":require("@/assets/images/icons/receipt.svg"),"alt":"icons"}})])]),(_vm.$store.state.general.step <= 4)?_c('PersonalInfo',{on:{"done":_vm.userDone}}):_vm._e(),(_vm.$store.state.general.step == 5)?_c('AddRevenue',{on:{"done":_vm.generateInvoice}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4 rounded-full bg-ansLemon"},[_c('img',{staticClass:"w-7 h-7 lg:w-12 lg:h-12",attrs:{"src":require("@/assets/images/icons/user.svg"),"alt":"icons"}})])
}]

export { render, staticRenderFns }